import React from 'react'
import './App.css'
import { BrowserRouter as BrowserRouter, Routes, Route } from 'react-router-dom' // Correct import for BrowserRouter
import Home from './pages/Home'
import InfoPage from './pages/InfoPage/InfoPage'
import { SuccessPage, PendingPage, FailurePage } from './pages/StatusPages/StatusPages'
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'
import Regulamin from './pages/Regulamin/Regulamin'

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/info" element={<InfoPage />} /> {/* InfoPage Route */}
				<Route path="/success" element={<SuccessPage />} />
				<Route path="/pending" element={<PendingPage />} />
				<Route path="/unknown" element={<PendingPage />} />
				<Route path="/failure" element={<FailurePage />} />
				<Route path="/polityka-prywatnosci" element={<PrivacyPolicy />} />
				<Route path="/regulamin" element={<Regulamin />} />
			</Routes>
		</BrowserRouter>
	)
}

export default App
