import React from 'react'
import { motion } from 'framer-motion'
import { CheckCircle, Clock, XCircle } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import './StatusPages.css' // Create this CSS file for styling

// Common layout component for all status pages
const StatusPageLayout = ({ children }) => {
	return (
		<div className="status-page-container">
			{/* Background gradient SVGs */}
			<svg
				className="blob-left-top"
				width="818"
				height="746"
				viewBox="0 0 818 746"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M240.5 230C165.5 222 31.1667 309.333 -33.5 355V746H817.5V112C771.833 70.5 589.5 -7.09998 484.5 44.5C379.5 96.1 315.5 238 240.5 230Z"
					fill="url(#paint0_linear_709_1102)"
				/>
				<defs>
					<linearGradient
						id="paint0_linear_709_1102"
						x1="182.708"
						y1="176.166"
						x2="474.66"
						y2="701.853"
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#3B82F6" />
						<stop offset="1" stopColor="#60A5FA" />
					</linearGradient>
				</defs>
			</svg>

			<svg
				className="blob-center"
				width="818"
				height="746"
				viewBox="0 0 818 746"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M240.5 230C165.5 222 31.1667 309.333 -33.5 355V746H817.5V112C771.833 70.5 589.5 -7.09998 484.5 44.5C379.5 96.1 315.5 238 240.5 230Z"
					fill="url(#paint1_linear_709_1102)"
				/>
				<defs>
					<linearGradient
						id="paint1_linear_709_1102"
						x1="173.353"
						y1="118.798"
						x2="465.305"
						y2="644.486"
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#3B82F6" />
						<stop offset="1" stopColor="#60A5FA" />
					</linearGradient>
				</defs>
			</svg>

			{children}
		</div>
	)
}

export const SuccessPage = () => {
	const navigate = useNavigate()

	return (
		<StatusPageLayout>
			<motion.div
				className="status-card success-card"
				initial={{ opacity: 0, scale: 0.9 }}
				animate={{ opacity: 1, scale: 1 }}
				transition={{ duration: 0.5 }}>
				<motion.div
					className="icon-container"
					initial={{ scale: 0 }}
					animate={{ scale: 1 }}
					transition={{ delay: 0.2, type: 'spring', stiffness: 200 }}>
					<CheckCircle size={80} className="status-icon success-icon" />
				</motion.div>

				<h1 className="status-title success-title">Sukces!</h1>
				<p className="status-message">
					Ebook został wysłany na twojego emaila! <span>(Sprawdź spam!)</span>
				</p>

				<motion.button
					className="status-button success-button"
					whileHover={{ scale: 1.05 }}
					whileTap={{ scale: 0.95 }}
					onClick={() => navigate('/')}>
					Strona główna
				</motion.button>
			</motion.div>
		</StatusPageLayout>
	)
}

export const PendingPage = () => {
	const navigate = useNavigate()

	return (
		<StatusPageLayout>
			<motion.div
				className="status-card pending-card"
				initial={{ opacity: 0, scale: 0.9 }}
				animate={{ opacity: 1, scale: 1 }}
				transition={{ duration: 0.5 }}>
				<motion.div
					className="icon-container"
					animate={{ rotate: 360 }}
					transition={{ repeat: Infinity, duration: 2, ease: 'linear' }}>
					<Clock size={80} className="status-icon pending-icon" />
				</motion.div>

				<h1 className="status-title pending-title">Przetwarzanie...</h1>
				<p className="status-message">Twoja płatność jest cały czas przetwarzana...</p>

				<div className="progress-bar-container">
					<motion.div
						className="progress-bar"
						initial={{ width: 0 }}
						animate={{ width: '100%' }}
						transition={{ repeat: Infinity, duration: 1.5, ease: 'easeInOut' }}
					/>
				</div>

				<motion.button
					className="status-button pending-button"
					whileHover={{ scale: 1.05 }}
					whileTap={{ scale: 0.95 }}
					onClick={() => navigate('/')}>
					Strona główna
				</motion.button>
			</motion.div>
		</StatusPageLayout>
	)
}

export const FailurePage = () => {
	const navigate = useNavigate()

	return (
		<StatusPageLayout>
			<motion.div
				className="status-card failure-card"
				initial={{ opacity: 0, scale: 0.9 }}
				animate={{ opacity: 1, scale: 1 }}
				transition={{ duration: 0.5 }}>
				<motion.div
					className="icon-container"
					initial={{ scale: 0 }}
					animate={{ scale: 1 }}
					transition={{ delay: 0.2, type: 'spring', stiffness: 200 }}>
					<XCircle size={80} className="status-icon failure-icon" />
				</motion.div>

				<h1 className="status-title failure-title">Oops!</h1>
				<p className="status-message">
					Coś poszło nie tak... <span>spróbuj ponownie!</span>
				</p>

				<motion.button
					className="status-button failure-button"
					whileHover={{ scale: 1.05 }}
					whileTap={{ scale: 0.95 }}
					onClick={() => navigate('/')}>
					Strona główna
				</motion.button>
			</motion.div>
		</StatusPageLayout>
	)
}
