import React from 'react'
import './Reviews.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faQuoteLeft } from '@fortawesome/free-solid-svg-icons'

// Import profile pictures
import pfp1 from '../../assets/pfp/1.jpg'
import pfp2 from '../../assets/pfp/2.jpg'
import pfp3 from '../../assets/pfp/3.jpg'
import pfp4 from '../../assets/pfp/4.jpg'
import pfp5 from '../../assets/pfp/5.jpg'

/**
 * Reviews component - displays customer testimonials with profile pictures
 * Shows actual user reviews with ratings in a card-based layout
 */
const Reviews = () => {
	// Array of review data
	const testimonials = [
		{
			id: 1,
			name: 'Marta K.',
			avatar: pfp1,
			rating: 5,
			text: 'Po miesiącu stosowania technik z poradnika udało mi się osiągnąć pierwszy świadomy sen! Niesamowite uczucie kontroli i wolności. Teraz mam świadome sny 2-3 razy w tygodniu.',
			date: '15 kwietnia 2023',
		},
		{
			id: 2,
			name: 'Dawid T.',
			avatar: pfp2,
			rating: 5,
			text: 'Fascynujące doświadczenie! Dzięki metodzie WILD opisanej w ebooku udało mi się przejść bezpośrednio ze stanu czuwania do świadomego snu. Wrażenia nie do opisania, polecam każdemu!',
			date: '3 maja 2023',
		},
		{
			id: 3,
			name: 'Anna S.',
			avatar: pfp3,
			rating: 5,
			text: 'Zawsze myślałam, że świadome sny to mit. Ten poradnik udowodnił mi, że się myliłam. Dzięki prostym wskazówkom i technikom Reality Checks udało mi się osiągnąć świadomy sen już po 2 tygodniach!',
			date: '21 marca 2023',
		},
		{
			id: 4,
			name: 'Kamil W.',
			avatar: pfp4,
			rating: 4,
			text: 'Super metody, szczególnie technika MILD okazała się dla mnie strzałem w dziesiątkę. Podoba mi się też aplikacja do śledzenia postępów, która była dodatkowym bonusem. Zdecydowanie warte swojej ceny.',
			date: '7 czerwca 2023',
		},
		{
			id: 5,
			name: 'Natalia R.',
			avatar: pfp5,
			rating: 5,
			text: 'Praktykuję świadome śnienie od kilku lat, ale dopiero dzięki zaawansowanym technikom z tego poradnika nauczyłam się utrzymywać świadomość przez cały sen. Teraz moje doświadczenia są o wiele bogatsze!',
			date: '12 kwietnia 2023',
		},
		{
			id: 6,
			name: 'Piotr B.',
			avatar: pfp3,
			rating: 5,
			text: 'Polecam każdemu, kto chce odkryć nieograniczone możliwości swojego umysłu. Ten poradnik to prawdziwa kopalnia wiedzy o świadomym śnieniu. Za tę cenę to absolutna okazja!',
			date: '29 maja 2023',
		},
	]

	// Render stars based on rating
	const renderStars = rating => {
		const stars = []
		for (let i = 0; i < rating; i++) {
			stars.push(<FontAwesomeIcon key={i} icon={faStar} className="review-star" />)
		}
		return stars
	}

	return (
		<section className="reviews-section" id="reviews">
			<div className="section-headline" data-aos="fade-up">
				<h2>
					Co mówią nasi <span>klienci</span>?
				</h2>
				<p>
					Dołącz do grona zadowolonych klientów, którzy zmienili swoje życie
					<br /> dzięki praktykowaniu świadomych snów
				</p>
			</div>

			<div className="testimonials-container" data-aos="fade-up">
				{testimonials.map(testimonial => (
					<div className="testimonial-card" key={testimonial.id}>
						<div className="quote-icon">
							<FontAwesomeIcon icon={faQuoteLeft} />
						</div>
						<div className="testimonial-content">
							<p className="testimonial-text">{testimonial.text}</p>
							<div className="testimonial-rating">{renderStars(testimonial.rating)}</div>
						</div>
						<div className="testimonial-author">
							<img src={testimonial.avatar} alt={`Zdjęcie profilowe ${testimonial.name}`} className="author-avatar" />
							<div className="author-details">
								<h4 className="author-name">{testimonial.name}</h4>
								<p className="testimonial-date">{testimonial.date}</p>
							</div>
						</div>
					</div>
				))}
			</div>

			<div className="review-cta" data-aos="fade-up">
				<a href="#pricing" className="review-cta-button">
					Dołącz do nich już dziś!
				</a>
			</div>
		</section>
	)
}

export default Reviews
