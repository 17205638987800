import React, { useState } from 'react'
import './FAQ.css'
import { ChevronDown, ChevronUp } from 'lucide-react'

const FAQ = () => {
	const [openIndex, setOpenIndex] = useState(null)

	const faqs = [
		{
			question: 'Czym są świadome sny?',
			answer:
				'Świadome sny to stan, w którym zdajesz sobie sprawę, że śnisz, podczas gdy nadal jesteś w trakcie snu. Daje to możliwość kontrolowania i wpływania na przebieg snu, co otwiera drzwi do nieograniczonych możliwości eksploracji i rozwoju osobistego.',
		},
		{
			question: 'Jak długo trwa nauka świadomego śnienia?',
			answer:
				'Czas nauki jest indywidualny i zależy od wielu czynników, takich jak regularność praktyki, wcześniejsze doświadczenia i predyspozycje. Niektórzy osiągają pierwsze świadome sny już po kilku tygodniach, podczas gdy inni mogą potrzebować kilku miesięcy regularnej praktyki.',
		},
		{
			question: 'Czy świadome sny są bezpieczne?',
			answer:
				'Tak, świadome sny są całkowicie bezpieczne. Jest to naturalny stan świadomości, który może wystąpić spontanicznie. Nasze techniki są oparte na sprawdzonych metodach i badaniach naukowych, a wszystkie ćwiczenia są bezpieczne dla zdrowia.',
		},
		{
			question: 'Jak otrzymam dostęp do materiałów po zakupie?',
			answer:
				'Natychmiast po dokonaniu płatności otrzymasz e-mail z linkiem do pobrania e-booka w formacie PDF. Dostęp do dodatkowych materiałów (jeśli są częścią wybranego pakietu) zostanie udzielony w tym samym e-mailu.',
		},
		{
			question: 'Czy mogę otrzymać zwrot pieniędzy jeśli nie będę zadowolony?',
			answer:
				'Tak, oferujemy 30-dniową gwarancję zwrotu pieniędzy. Jeśli po przeczytaniu e-booka i zastosowaniu technik nie osiągniesz żadnych rezultatów, zwrócimy Ci pełną kwotę zakupu.',
		},
		{
			question: 'Czy potrzebuję specjalnego sprzętu?',
			answer:
				'Nie, do praktykowania świadomych snów nie potrzebujesz żadnego specjalnego sprzętu. Wszystkie techniki opisane w e-booku można wykonywać bez dodatkowych urządzeń. Niektóre pakiety zawierają jednak dodatkowe materiały audio i aplikacje, które mogą pomóc w praktyce.',
		},
		{
			question: 'Czy mogę ćwiczyć świadome sny codziennie?',
			answer:
				'Tak, możesz ćwiczyć codziennie, choć zalecamy zaczęcie od 3-4 sesji w tygodniu, aby dać organizmowi czas na adaptację. Ważne jest, aby słuchać swojego ciała i nie przeciążać się.',
		},
		{
			question: 'Czy świadome sny mogą pomóc w rozwoju osobistym?',
			answer:
				'Tak, świadome sny są potężnym narzędziem rozwoju osobistego. Mogą pomóc w przełamywaniu ograniczeń, rozwiązywaniu problemów, rozwijaniu kreatywności, a nawet w terapii. Wiele osób wykorzystuje je do pracy nad sobą i osiągania celów.',
		},
	]

	const toggleFAQ = index => {
		setOpenIndex(openIndex === index ? null : index)
	}

	return (
		<section className="faq-section" id="faq">
			<div className="section-headline">
				<h2>
					Często zadawane <span>pytania</span>
				</h2>
				<p>Znajdź odpowiedzi na najczęściej zadawane pytania dotyczące świadomych snów i naszych produktów</p>
			</div>

			<div className="faq-container">
				{faqs.map((faq, index) => (
					<div
						key={index}
						className={`faq-item ${openIndex === index ? 'active' : ''}`}
						onClick={() => toggleFAQ(index)}>
						<div className="faq-question">
							<h3>{faq.question}</h3>
							{openIndex === index ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
						</div>
						{openIndex === index && (
							<div className="faq-answer">
								<p>{faq.answer}</p>
							</div>
						)}
					</div>
				))}
			</div>
		</section>
	)
}

export default FAQ
