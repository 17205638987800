import React from 'react'
import './Regulamin.css'
import Footer from '../../components/footer/footer'
import { Mail, MapPin } from 'lucide-react'

const Regulamin = () => {
	return (
		<div className="regulamin">
			{/* Background gradient SVGs */}
			<svg
				className="blob-left-top"
				width="818"
				height="746"
				viewBox="0 0 818 746"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M240.5 230C165.5 222 31.1667 309.333 -33.5 355V746H817.5V112C771.833 70.5 589.5 -7.09998 484.5 44.5C379.5 96.1 315.5 238 240.5 230Z"
					fill="url(#paint0_linear_709_1102)"
				/>
				<defs>
					<linearGradient
						id="paint0_linear_709_1102"
						x1="182.708"
						y1="176.166"
						x2="474.66"
						y2="701.853"
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#3B82F6" />
						<stop offset="1" stopColor="#60A5FA" />
					</linearGradient>
				</defs>
			</svg>

			<svg
				className="blob-center"
				width="818"
				height="746"
				viewBox="0 0 818 746"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M240.5 230C165.5 222 31.1667 309.333 -33.5 355V746H817.5V112C771.833 70.5 589.5 -7.09998 484.5 44.5C379.5 96.1 315.5 238 240.5 230Z"
					fill="url(#paint1_linear_709_1102)"
				/>
				<defs>
					<linearGradient
						id="paint1_linear_709_1102"
						x1="173.353"
						y1="118.798"
						x2="465.305"
						y2="644.486"
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#3B82F6" />
						<stop offset="1" stopColor="#60A5FA" />
					</linearGradient>
				</defs>
			</svg>

			<div className="regulamin-container">
				<h1>Regulamin Sklepu Internetowego Dreamity</h1>

				<section className="seller-info">
					<h2>Dane Sprzedawcy</h2>
					<p>
						<strong>Sprzedawca:</strong> BLONEKDEV Antoni Bartłomiej Blonkowski
						<br />
						<strong>Adres siedziby:</strong> ul. Mławka 25, 13-240 Mławka
						<br />
						<strong>NIP:</strong> 5711727874
						<br />
						<strong>REGON:</strong> 528658529
						<br />
						<strong>E-mail:</strong> blonekdev@gmail.com
					</p>
				</section>

				<section>
					<h2>1. Postanowienia ogólne</h2>
					<p>
						Niniejszy regulamin określa zasady korzystania ze sklepu internetowego Dreamity, prowadzonego przez
						BLONEKDEV Antoni Bartłomiej Blonkowski. Sklep prowadzi sprzedaż produktów cyfrowych za pośrednictwem sieci
						Internet.
					</p>
				</section>

				<section>
					<h2>2. Oferowane produkty i ceny</h2>
					<p>Sklep Dreamity oferuje następujące produkty cyfrowe:</p>
					<ul>
						<li>Ebooki w formacie PDF</li>
					</ul>
					<p>
						Wszystkie ceny podane są w złotych polskich (PLN) i zawierają podatek VAT. Cena podana przy każdym produkcie
						jest wiążąca w chwili złożenia zamówienia. W cenie produktów nie są zawarte koszty dostawy, ponieważ
						produkty dostarczane są drogą elektroniczną.
					</p>
				</section>

				<section>
					<h2>3. Formy płatności i dostawy</h2>
					<p>
						<strong>Płatności:</strong> Rozliczenia transakcji e-przelewem przeprowadzane są za pośrednictwem Centrum
						Rozliczeniowego HotPay. Dane do płatności są przekazywane w sposób zaszyfrowany.
					</p>
					<p>
						<strong>Dostawa:</strong> Produkty cyfrowe są dostarczane automatycznie na podany adres e-mail natychmiast
						po zaksięgowaniu płatności.
					</p>
				</section>

				<section>
					<h2>4. Termin realizacji zamówienia</h2>
					<ul>
						<li>Zamówienia są realizowane natychmiast po potwierdzeniu płatności</li>
						<li>Maksymalny czas oczekiwania na dostarczenie produktu to 24 godziny</li>
						<li>W przypadku problemów technicznych, klient zostanie poinformowany o przewidywanym czasie realizacji</li>
					</ul>
				</section>

				<section>
					<h2>5. Ograniczenia w dostarczaniu</h2>
					<ul>
						<li>Produkty są dostępne wyłącznie w formie cyfrowej</li>
						<li>Wymagany jest dostęp do internetu do pobrania produktów</li>
						<li>Niektóre produkty mogą wymagać określonego oprogramowania do odczytu (np. czytnik PDF)</li>
					</ul>
				</section>

				<section>
					<h2>6. Polityka zwrotów</h2>
					<p>
						Zgodnie z art. 38 ust. 13 ustawy o prawach konsumenta,{' '}
						<strong>nie przysługuje prawo odstąpienia od umowy</strong> w przypadku dostarczenia treści cyfrowych, które
						nie są zapisane na nośniku materialnym, jeżeli spełnianie świadczenia rozpoczęło się za wyraźną zgodą
						konsumenta przed upływem terminu do odstąpienia od umowy i po poinformowaniu przez przedsiębiorcę o utracie
						prawa odstąpienia od umowy.
					</p>
					<p>
						W przypadku problemów technicznych z dostępem do produktu, prosimy o kontakt w celu rozwiązania problemu.
					</p>
				</section>

				<section>
					<h2>7. Tryb składania i rozpatrywania reklamacji</h2>
					<ul>
						<li>Reklamacje należy składać w formie elektronicznej na adres: blonekdev@gmail.com</li>
						<li>W reklamacji należy podać: numer zamówienia, opis problemu, dane kontaktowe</li>
						<li>Reklamacje są rozpatrywane w terminie 14 dni od daty otrzymania</li>
						<li>Odpowiedź na reklamację zostanie przesłana na podany adres e-mail</li>
					</ul>
				</section>

				<section>
					<h2>8. Warunki zawierania i rozwiązywania umów</h2>
					<ul>
						<li>Umowa jest zawierana w momencie dokonania płatności</li>
						<li>Umowa jest zawierana na czas nieokreślony w przypadku dostępu do platformy</li>
						<li>Umowa może zostać rozwiązana przez obie strony z zachowaniem 14-dniowego okresu wypowiedzenia</li>
						<li>
							W przypadku naruszenia regulaminu przez użytkownika, sklep zastrzega sobie prawo do natychmiastowego
							rozwiązania umowy
						</li>
					</ul>
				</section>

				<section>
					<h2>9. Gwarancje i rękojmia</h2>
					<ul>
						<li>Sklep gwarantuje zgodność produktów z opisem</li>
						<li>W przypadku wad produktu, przysługuje prawo do reklamacji w terminie 2 lat od daty zakupu</li>
						<li>Sklep zobowiązuje się do usunięcia wad w terminie 14 dni od otrzymania reklamacji</li>
						<li>W przypadku braku możliwości usunięcia wady, przysługuje prawo do zwrotu środków</li>
					</ul>
				</section>

				<section>
					<h2>10. Ochrona danych osobowych</h2>
					<p>
						Administratorem danych osobowych jest BLONEKDEV. Szczegółowe informacje dotyczące przetwarzania danych
						osobowych znajdują się w Polityce Prywatności dostępnej na stronie sklepu.
					</p>
				</section>

				<section>
					<h2>11. Informacja o operatorze płatności</h2>
					<p>
						Rozliczenia transakcji kartą płatniczą i e-przelewem przeprowadzane są za pośrednictwem Centrum
						Rozliczeniowego HotPay. Dane do płatności przekazywane są w sposób zaszyfrowany i nie są przechowywane na
						serwerach sklepu.
					</p>
				</section>

				<section className="contact-section">
					<h2>12. Kontakt ze Sprzedawcą</h2>
					<p>Dostępne formy kontaktu:</p>
					<div className="contact-info">
						<div className="contact-item">
							<Mail size={20} />
							<span>E-mail: blonekdev@gmail.com</span>
						</div>
						<div className="contact-item">
							<MapPin size={20} />
							<span>Adres: ul. Mławka 25, 13-240 Mławka</span>
						</div>
					</div>
				</section>

				<div className="regulamin-footer">
					<p>
						<strong>Data wejścia w życie: 03.03.2024</strong>
					</p>
				</div>
			</div>

			<Footer />
		</div>
	)
}

export default Regulamin
