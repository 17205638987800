import React, { useEffect, useState } from 'react'
import './Home.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faFaceLaughWink,
	faGift,
	faStar,
	faDollarSign,
	faBook,
	faMoon,
	faBrain,
} from '@fortawesome/free-solid-svg-icons'
import Footer from '../components/footer/footer'
import { useNavigate, useLocation } from 'react-router-dom'
import { BookOpen, ThumbsUp, DollarSign, CheckCircle, Moon, Gift } from 'lucide-react'
import Reviews from '../components/Reviews/Reviews'
import FAQ from '../components/FAQ/FAQ'

import { ReactComponent as MySVG } from '../assets/GraduationHat.svg'
import HeroIMG from '../assets/Height/hero.png'
import pfp1 from '../assets/pfp/1.jpg'
import pfp2 from '../assets/pfp/2.jpg'
import pfp3 from '../assets/pfp/3.jpg'
import pfp4 from '../assets/pfp/4.jpg'
import pfp5 from '../assets/pfp/5.jpg'

import review1 from '../assets/review-ss/review-1.png'
import review2 from '../assets/review-ss/review-2.png'
import review3 from '../assets/review-ss/review-3.png'
import review4 from '../assets/review-ss/review-4.png'
import review5 from '../assets/review-ss/review-5.png'
import review6 from '../assets/review-ss/review-6.png'
import review7 from '../assets/review-ss/review-7.png'

// Import hero images
import heroImage1 from '../assets/hero/hero.png'
import heroImage2 from '../assets/hero/hero1.png'
import heroImage3 from '../assets/hero/hero2.png'

function Home() {
	const navigate = useNavigate()
	const location = useLocation()
	const [refCode, setRefCode] = useState('')
	const [currentSlide, setCurrentSlide] = useState(0)
	const [timeLeft, setTimeLeft] = useState({
		hours: 23,
		minutes: 59,
		seconds: 59,
	})

	// Slider images array with imported images
	const sliderImages = [
		{ id: 1, src: heroImage1 },
		{ id: 2, src: heroImage2 },
		{ id: 3, src: heroImage3 },
	]

	// Auto slide functionality
	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentSlide(prev => (prev + 1) % sliderImages.length)
		}, 5000) // Change slide every 5 seconds

		return () => clearInterval(interval)
	}, [])

	// Handle dot click
	const handleDotClick = index => {
		setCurrentSlide(index)
	}

	// Extract ref code from URL when component mounts
	useEffect(() => {
		const queryParams = new URLSearchParams(location.search)
		const ref = queryParams.get('ref')
		if (ref) {
			setRefCode(ref)
		}
	}, [location])

	useEffect(() => {
		const timer = setInterval(() => {
			setTimeLeft(prev => {
				if (prev.seconds > 0) {
					return { ...prev, seconds: prev.seconds - 1 }
				}
				if (prev.minutes > 0) {
					return { ...prev, minutes: prev.minutes - 1, seconds: 59 }
				}
				if (prev.hours > 0) {
					return { ...prev, hours: prev.hours - 1, minutes: 59, seconds: 59 }
				}
				return prev
			})
		}, 1000)

		return () => clearInterval(timer)
	}, [])

	const handlePurchase = amount => {
		localStorage.setItem('selectedAmount', amount) // Store amount in localStorage

		// Navigate to InfoPage with ref parameter if available
		if (refCode) {
			navigate(`/info?ref=${refCode}`)
		} else {
			navigate('/info')
		}
	}

	return (
		<>
			<div className="subheader">
				<div className="subheader-content">
					<h6>
						<span>🔥 Zniżka -70%</span>
						<div className="countdown">
							<span className="countdown-item">{timeLeft.hours.toString().padStart(2, '0')}</span>:
							<span className="countdown-item">{timeLeft.minutes.toString().padStart(2, '0')}</span>:
							<span className="countdown-item">{timeLeft.seconds.toString().padStart(2, '0')}</span>
						</div>
					</h6>
				</div>
			</div>

			<header>
				<div className="text-hero">
					<h1>
						<b>
							Odkryj <span>Świadome</span>
						</b>
						<br /> Sny i Kontroluj Swoje Marzenia!{' '}
					</h1>
					<p>
						Poznaj sprawdzone techniki, które pomogły tysiącom osób osiągnąć świadome sny i zapanować nad swoim umysłem
						podczas snu.
					</p>
					<div className="form-CTA">
						<a href="#pricing">
							{' '}
							<button>
								<Moon size={18} className="mr-2" /> Odbierz poradnik!
							</button>
						</a>
						<p className="button-info">
							<span>
								<Gift size={18} className="mr-1" /> %70 znizki
							</span>{' '}
							Dla następnych 100 osób!
						</p>
						<div className="reviews-stars">
							<div className="avatars-stars">
								<img src={pfp1} alt="" className="img-first" />
								<img src={pfp2} alt="" />
								<img src={pfp3} alt="" />
								<img src={pfp4} alt="" />
								<img src={pfp5} alt="" />
							</div>
							<div className="review-stars">
								<div className="stars-reviews">
									<FontAwesomeIcon icon={faStar} className="fa-star" />
									<FontAwesomeIcon icon={faStar} />
									<FontAwesomeIcon icon={faStar} />
									<FontAwesomeIcon icon={faStar} />
									<FontAwesomeIcon icon={faStar} />
								</div>
								<div className="stars-info-reviews">
									<span>990+</span> osób nam zaufało.
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="image-hero">
					<div className="slider-container">
						{sliderImages.map((image, index) => (
							<div
								key={image.id}
								className={`slide ${index === currentSlide ? 'active' : ''}`}
								style={{
									backgroundImage: `url(${image.src})`,
									filter: 'drop-shadow(0px 0px 70px #60a5fa)',
								}}
							/>
						))}
						<div className="slider-dots">
							{sliderImages.map((_, index) => (
								<div
									key={index}
									className={`dot ${index === currentSlide ? 'active' : ''}`}
									onClick={() => handleDotClick(index)}
								/>
							))}
						</div>
					</div>
				</div>
			</header>

			<section className="benefits">
				<div className="container mx-auto px-4 py-20">
					<div className="section-headline">
						<h2>
							Dlaczego <span>My</span>?
						</h2>
						<p>Oferujemy kompleksowe rozwiązania dla osób pragnących odkryć świat świadomych snów</p>
					</div>

					<div className="benefits-grid">
						<div className="benefit-card">
							<div className="benefit-number">01</div>
							<div className="benefit-content">
								<div className="benefit-icon">
									<BookOpen size={20} />
								</div>
								<h3 className="benefit-title">Dopracowany e-book</h3>
								<p className="benefit-description">
									Kompletny przewodnik zawierający szczegółowe techniki, inspirujące przykłady i praktyczne wskazówki do
									kontroli swoich snów.
								</p>
							</div>
						</div>

						<div className="benefit-card">
							<div className="benefit-number">02</div>
							<div className="benefit-content">
								<div className="benefit-icon">
									<ThumbsUp size={20} />
								</div>
								<h3 className="benefit-title">Sprawdzone metody</h3>
								<p className="benefit-description">
									Nasze techniki pomogły już ponad 990+ osobom osiągnąć świadome sny i odkryć nieograniczone możliwości
									swojego umysłu.
								</p>
							</div>
						</div>

						<div className="benefit-card">
							<div className="benefit-number">03</div>
							<div className="benefit-content">
								<div className="benefit-icon">
									<DollarSign size={20} />
								</div>
								<h3 className="benefit-title">Dostępna cena</h3>
								<p className="benefit-description">
									Najlepsza wartość w przystępnej cenie. Nie musisz wydawać fortuny, aby rozpocząć przygodę ze
									świadomymi snami.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Reviews />

			<section className="pricing" id="pricing">
				<div className="section-headline" data-aos="fade-up">
					<h2>
						Gotowy odkryć <span>nieograniczone</span> możliwości swoich snów?
					</h2>
					<p>
						<span>990+ </span> Tyle osób nam zaufało, będziesz następny?
					</p>
				</div>
				<div className="ebooks" data-aos="fade-up">
					<div className="e-book">
						<div className="background-title">
							<h3 className="title">#1 Reality Checks </h3>
						</div>
						<h3>Najłatwiejsza Technika</h3>
						<h2 className="pricetag2">42.40 zł</h2>
						<h2 className="pricetag">24.99 zł</h2>
						<p className="info">Cena uwzglęgnia podatek*</p>
						<button onClick={() => handlePurchase(24.99)}>Kup teraz</button>
						<h4 className="feature-title">Co posiada E-book</h4>
						<div className="feature">
							<CheckCircle size={16} className="feature-icon" />
							<p>Kompletny przewodnik po Reality Checks - najprostszej metodzie osiągania świadomych snów</p>
						</div>
						<div className="feature">
							<CheckCircle size={16} className="feature-icon" />
							<p>Dziennik snów - jak prowadzić i analizować</p>
						</div>
						<div className="feature">
							<CheckCircle size={16} className="feature-icon" />
							<p>Podstawowa kontrola świadomych snów o niskiej wyrazistości</p>
						</div>
						<div className="bonus-section">
							<h4 className="feature-title">Bonusy</h4>
							<div className="feature">
								<CheckCircle size={16} className="feature-icon" />
								<p>5 prostych ćwiczeń świadomości na dzień</p>
							</div>
							<div className="feature">
								<CheckCircle size={16} className="feature-icon" />
								<p>Lista najczęstszych znaków sennych</p>
							</div>
						</div>
					</div>

					<div className="e-book e-book-main">
						<div className="information-popular">
							<h4>Najczęściej wybierany</h4>
						</div>
						<div className="background-title">
							<h3 className="title">#2 MILD </h3>
						</div>
						<h3>Średnio-zaawansowana Technika</h3>
						<h2 className="pricetag2">76.00 zł</h2>
						<h2 className="pricetag">37.99 zł</h2>
						<p className="info">Cena uwzglęgnia podatek*</p>
						<button onClick={() => handlePurchase(37.99)}>Kup teraz</button>
						<h4 className="feature-title">Co posiada E-book</h4>
						<div className="feature">
							<CheckCircle size={16} className="feature-icon" />
							<p>Technika MILD (Mnemonic Induction of Lucid Dreams) - średnio trudna, ale bardzo skuteczna</p>
						</div>
						<div className="feature">
							<CheckCircle size={16} className="feature-icon" />
							<p>Zaawansowane metody kontroli i utrzymania świadomości podczas snu o średniej wyrazistości</p>
						</div>
						<div className="feature">
							<CheckCircle size={16} className="feature-icon" />
							<p>Zaawansowany dziennik snów z systemem analizy</p>
						</div>
						<div className="bonus-section">
							<h4 className="feature-title">Bonusy</h4>
							<div className="feature">
								<CheckCircle size={16} className="feature-icon" />
								<p>10 zaawansowanych ćwiczeń świadomości i Intent</p>
							</div>
							<div className="feature">
								<CheckCircle size={16} className="feature-icon" />
								<p>Aplikacja do śledzenia postępów (dostęp online)</p>
							</div>
							<div className="feature">
								<CheckCircle size={16} className="feature-icon" />
								<p>Podstawowe afirmacje i programowanie snu</p>
							</div>
						</div>
					</div>

					<div className="e-book">
						<div className="background-title">
							<h3 className="title">#3 WILD </h3>
						</div>
						<h3>Najtrudniejsza i Najlepsza Technika</h3>
						<h2 className="pricetag2">135.99 zł</h2>
						<h2 className="pricetag">79.99 zł</h2>
						<p className="info">Cena uwzglęgnia podatek*</p>
						<button onClick={() => handlePurchase(79.99)}>Kup teraz</button>
						<h4 className="feature-title">Co posiada E-book</h4>
						<div className="feature">
							<CheckCircle size={16} className="feature-icon" />
							<p>
								Technika WILD (Wake Induced Lucid Dreaming) - najtrudniejsza, ale dająca sny o najwyższej wyrazistości
							</p>
						</div>
						<div className="feature">
							<CheckCircle size={16} className="feature-icon" />
							<p>Metody głębokiej eksploracji świadomości z pełną kontrolą w snach o niezwykłej jakości</p>
						</div>
						<div className="feature">
							<CheckCircle size={16} className="feature-icon" />
							<p>Kompletny system optymalizacji snu dla maksymalnej świadomości i ultra-wyrazistych snów</p>
						</div>
						<div className="bonus-section">
							<h4 className="feature-title">Bonusy</h4>
							<div className="feature">
								<CheckCircle size={16} className="feature-icon" />
								<p>Kurs audio z prowadzonymi medytacjami WILD (6 godzin)</p>
							</div>
							<div className="feature">
								<CheckCircle size={16} className="feature-icon" />
								<p>Aplikacja z pełnym dostępem premium do funkcji analizy snu</p>
							</div>
							<div className="feature">
								<CheckCircle size={16} className="feature-icon" />
								<p>Konsultacja online z ekspertem WILD i świadomego śnienia</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<FAQ />

			<Footer />
		</>
	)
}

export default Home
