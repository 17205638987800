import React from 'react'
import './PrivacyPolicy.css'
import Footer from '../../components/footer/footer'
import { Mail, MapPin } from 'lucide-react'

const PrivacyPolicy = () => {
	return (
		<div className="privacy-policy">
			{/* Background gradient SVGs */}
			<svg
				className="blob-left-top"
				width="818"
				height="746"
				viewBox="0 0 818 746"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M240.5 230C165.5 222 31.1667 309.333 -33.5 355V746H817.5V112C771.833 70.5 589.5 -7.09998 484.5 44.5C379.5 96.1 315.5 238 240.5 230Z"
					fill="url(#paint0_linear_709_1102)"
				/>
				<defs>
					<linearGradient
						id="paint0_linear_709_1102"
						x1="182.708"
						y1="176.166"
						x2="474.66"
						y2="701.853"
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#3B82F6" />
						<stop offset="1" stopColor="#60A5FA" />
					</linearGradient>
				</defs>
			</svg>

			<svg
				className="blob-center"
				width="818"
				height="746"
				viewBox="0 0 818 746"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M240.5 230C165.5 222 31.1667 309.333 -33.5 355V746H817.5V112C771.833 70.5 589.5 -7.09998 484.5 44.5C379.5 96.1 315.5 238 240.5 230Z"
					fill="url(#paint1_linear_709_1102)"
				/>
				<defs>
					<linearGradient
						id="paint1_linear_709_1102"
						x1="173.353"
						y1="118.798"
						x2="465.305"
						y2="644.486"
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#3B82F6" />
						<stop offset="1" stopColor="#60A5FA" />
					</linearGradient>
				</defs>
			</svg>

			<div className="privacy-container">
				<h1>Polityka Prywatności Sklepu Internetowego Dreamity</h1>

				<section className="seller-info">
					<h2>Dane Administratora</h2>
					<p>
						<strong>Administrator:</strong> BLONEKDEV Antoni Bartłomiej Blonkowski
						<br />
						<strong>Adres siedziby:</strong> ul. Mławka 25, 13-240 Mławka
						<br />
						<strong>NIP:</strong> 5711727874
						<br />
						<strong>REGON:</strong> 528658529
						<br />
						<strong>E-mail:</strong> blonekdev@gmail.com
					</p>
				</section>

				<section>
					<h2>1. Informacje ogólne</h2>
					<p>
						Niniejsza Polityka Prywatności określa zasady przetwarzania i ochrony danych osobowych przekazanych przez
						Użytkowników w związku z korzystaniem ze sklepu internetowego Dreamity.
					</p>
				</section>

				<section>
					<h2>2. Gromadzone dane</h2>
					<p>Administrator zbiera następujące dane osobowe:</p>
					<ul>
						<li>Adres e-mail - niezbędny do realizacji zamówienia i dostarczenia zakupionych produktów</li>
						<li>Dane do faktury (jeśli zostały podane)</li>
					</ul>
					<p>
						Podanie danych jest dobrowolne, ale konieczne do realizacji zamówień. Dane są przetwarzane zgodnie z
						Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 (RODO).
					</p>
				</section>

				<section>
					<h2>3. Cele przetwarzania danych</h2>
					<ul>
						<li>Realizacja zamówień – wysyłka zakupionych ebooków na podany adres e-mail.</li>
						<li>Utrzymanie kontaktu z klientem – w sprawach związanych z zakupem.</li>
					</ul>
				</section>

				<section>
					<h2>4. Prawa użytkowników</h2>
					<ul>
						<li>Prawo dostępu do danych – użytkownik ma prawo wiedzieć, jakie dane są przetwarzane i w jaki sposób.</li>
						<li>Prawo do sprostowania danych – możliwość poprawienia błędnych danych.</li>
						<li>Prawo do ograniczenia przetwarzania.</li>
						<li>Prawo do sprzeciwu wobec przetwarzania danych.</li>
					</ul>
					<p>
						W celu realizacji powyższych praw należy skontaktować się z administratorem danych: blonekdev@gmail.com.
					</p>
				</section>

				<section>
					<h2>5. Płatności</h2>
					<p>Płatności w sklepie internetowym są realizowane za pośrednictwem operatora HotPay.</p>
				</section>

				<section>
					<h2>6. Polityka zwrotów</h2>
					<p>
						Zgodnie z obowiązującymi przepisami, sklep BLONEKDEV nie przyjmuje zwrotów zakupionych ebooków ponieważ są
						to produkty cyfrowe dostarczane w formie niematerialnej.
					</p>
				</section>

				<section>
					<h2>7. Pliki Cookies</h2>
					<p>Sklep internetowy BLONEKDEV wykorzystuje pliki cookies w celu:</p>
					<ul>
						<li>Analizy ruchu na stronie.</li>
					</ul>
					<p>Użytkownicy mogą zarządzać ustawieniami cookies za pośrednictwem ustawień przeglądarki.</p>
				</section>

				<section>
					<h2>8. Bezpieczeństwo płatności</h2>
					<p>
						Płatności elektroniczne realizowane są przez Centrum Rozliczeniowe HotPay. Dane dotyczące płatności są
						przekazywane w formie zaszyfrowanej i nie są przechowywane na serwerach sklepu.
					</p>
				</section>

				<section className="contact-section">
					<h2>9. Kontakt w sprawach prywatności</h2>
					<p>W sprawach związanych z ochroną danych osobowych można kontaktować się z Administratorem:</p>
					<div className="contact-info">
						<div className="contact-item">
							<Mail size={20} />
							<span>E-mail: blonekdev@gmail.com</span>
						</div>
						<div className="contact-item">
							<MapPin size={20} />
							<span>Adres: ul. Mławka 25, 13-240 Mławka</span>
						</div>
					</div>
				</section>

				<div className="policy-footer">
					<p>
						<strong>Data wejścia w życie: 03.03.2024</strong>
					</p>
				</div>
			</div>

			<Footer />
		</div>
	)
}

export default PrivacyPolicy
