import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { motion } from 'framer-motion' // Import framer-motion for animations
import './InfoPage.css' // Assuming you have this CSS file for custom styles

function InfoPage() {
	const [email, setEmail] = useState('')
	const [emailConfirm, setEmailConfirm] = useState('')
	const [emailError, setEmailError] = useState('')
	const [refCode, setRefCode] = useState('')
	const [acceptTerms, setAcceptTerms] = useState(false)
	const [termsError, setTermsError] = useState('')
	const navigate = useNavigate()
	const location = useLocation()

	// Extract ref code from URL when component mounts
	useEffect(() => {
		// Parse URL for ref query parameter
		const queryParams = new URLSearchParams(location.search)
		const ref = queryParams.get('ref')
		if (ref) {
			setRefCode(ref)
		}
	}, [location])

	const validateEmail = email => {
		const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
		return re.test(String(email).toLowerCase())
	}

	const handleEmailChange = e => {
		setEmail(e.target.value)
		if (emailConfirm && e.target.value !== emailConfirm) {
			setEmailError('Emails do not match')
		} else {
			setEmailError('')
		}
	}

	const handleEmailConfirmChange = e => {
		setEmailConfirm(e.target.value)
		if (email && e.target.value !== email) {
			setEmailError('Emails do not match')
		} else {
			setEmailError('')
		}
	}

	const handleTermsChange = e => {
		setAcceptTerms(e.target.checked)
		setTermsError('')
	}

	const handleSubmit = async event => {
		event.preventDefault()

		if (!validateEmail(email)) {
			setEmailError('Please enter a valid email address')
			return
		}

		if (email !== emailConfirm) {
			setEmailError('Emails do not match')
			return
		}

		if (!acceptTerms) {
			setTermsError('Musisz zaakceptować regulamin i politykę prywatności')
			return
		}

		const amount = localStorage.getItem('selectedAmount')

		try {
			// Include ref code in the payment request if available
			const paymentData = {
				email,
				amount,
			}

			// Add ref code if available
			if (refCode) {
				paymentData.ref = refCode
			}

			const response = await axios.post('https://titanmethod.shop/api/pay', paymentData)
			const { url } = response.data
			window.location.href = url // Redirect to HotPay
		} catch (error) {
			console.error('Error initiating payment', error.message)
		}
	}

	return (
		<div className="info-page-container">
			{/* Background gradient SVGs */}
			<svg
				className="blob-left-top"
				width="818"
				height="746"
				viewBox="0 0 818 746"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M240.5 230C165.5 222 31.1667 309.333 -33.5 355V746H817.5V112C771.833 70.5 589.5 -7.09998 484.5 44.5C379.5 96.1 315.5 238 240.5 230Z"
					fill="url(#paint0_linear_709_1102)"
				/>
				<defs>
					<linearGradient
						id="paint0_linear_709_1102"
						x1="182.708"
						y1="176.166"
						x2="474.66"
						y2="701.853"
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#3B82F6" />
						<stop offset="1" stopColor="#60A5FA" />
					</linearGradient>
				</defs>
			</svg>

			<svg
				className="blob-center"
				width="818"
				height="746"
				viewBox="0 0 818 746"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path
					d="M240.5 230C165.5 222 31.1667 309.333 -33.5 355V746H817.5V112C771.833 70.5 589.5 -7.09998 484.5 44.5C379.5 96.1 315.5 238 240.5 230Z"
					fill="url(#paint1_linear_709_1102)"
				/>
				<defs>
					<linearGradient
						id="paint1_linear_709_1102"
						x1="173.353"
						y1="118.798"
						x2="465.305"
						y2="644.486"
						gradientUnits="userSpaceOnUse">
						<stop stopColor="#3B82F6" />
						<stop offset="1" stopColor="#60A5FA" />
					</linearGradient>
				</defs>
			</svg>

			<motion.div
				className="form-wrapper"
				initial={{ opacity: 0, scale: 0.9 }}
				animate={{ opacity: 1, scale: 1 }}
				transition={{ duration: 0.5 }}>
				<h1 className="form-title">Na ten adres email wyślemy E-booka</h1>

				{refCode && (
					<div className="referral-info">
						<span>Kupujesz z polecenia:</span> {refCode}
					</div>
				)}

				<form onSubmit={handleSubmit} className="form">
					<div className="form-group">
						<label htmlFor="email" className="form-label">
							Adres Email
						</label>
						<input
							id="email"
							name="email"
							type="email"
							value={email}
							onChange={handleEmailChange}
							required
							className="form-input"
							placeholder="Twój adres email"
						/>
					</div>

					<div className="form-group">
						<label htmlFor="emailConfirm" className="form-label">
							Potwierdź Adres Email
						</label>
						<input
							id="emailConfirm"
							name="emailConfirm"
							type="email"
							value={emailConfirm}
							onChange={handleEmailConfirmChange}
							required
							className="form-input"
							placeholder="Potwierdź adres email"
						/>
						{emailError && <p className="error-text">{emailError}</p>}
					</div>

					<div className="terms-group">
						<label className="terms-label">
							<input type="checkbox" checked={acceptTerms} onChange={handleTermsChange} className="terms-checkbox" />
							<span className="terms-text">
								Akceptuję{' '}
								<Link to="/regulamin" className="terms-link">
									regulamin
								</Link>{' '}
								oraz{' '}
								<Link to="/polityka-prywatnosci" className="terms-link">
									politykę prywatności
								</Link>
							</span>
						</label>
						{termsError && <p className="error-text">{termsError}</p>}
					</div>

					<motion.button type="submit" className="form-button" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
						Kontynuuj Zakup
					</motion.button>
				</form>
			</motion.div>
		</div>
	)
}

export default InfoPage
